import k from "../kaboom";

export default function Animations() {
  // k.setGravity(500)

  const player = k.add([
    k.sprite("tiles", { animSpeed: 0.5, frame: 0 }),
    k.pos(k.width() * 0.5, k.height() * 0.5),
    k.anchor("center"),
    k.scale(1),
  ]);

  k.onKeyPress('left', () => {
    player.scale.x = -1
    player.play('move')
  })

  k.onKeyPress('right', () => {
    player.scale.x = 1
    player.play('move')
  })

  k.onKeyRelease('left', () => {
    player.play('idle')
  })

  k.onKeyRelease('right', () => {
    player.play('idle')
  })

  // k.add([
  //   k.pos(k.width() * 0.5, k.height() * 0.25),
  //   k.text('Hello there!'),
  //   k.anchor('center'),
  //   k.area(),
  //   k.body(),
  // ])

  // k.add([
  //   k.rect(k.width(), 50),
  //   k.color(255,255,55),
  //   k.pos(0, k.height()),
  //   k.area(),
  //   k.body({ isStatic: true })
  // ])
}
