import kaboom from 'kaboom'

const k = kaboom({
  scale: 1,
  width: 320,
  height: 572,
  background: [0,0,0],
  letterbox: true
})

export default k