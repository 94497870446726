import k from "./kaboom";
import Animations from "./scenes/animations";
import Collisions from "./scenes/collisions";

import HelloWorld from "./scenes/hello-world";
import ZoomingAndPanning from "./scenes/zooming-and-panning";
import Level from "./scenes/level";
import Pacman from "./scenes/pacman";

import pacmanImg from './assets/pacman.png'

k.loadSprite('tiles', pacmanImg, {
  sliceX: 9,
  sliceY: 9,
  anims: {
    idle: { from: 0, to: 0 },
    move: { from: 0, to: 2, loop: true },
  }
})

k.scene('hello-world', HelloWorld)
k.scene('collisions', Collisions)
k.scene('animations', Animations)
k.scene('zooming-and-panning', ZoomingAndPanning)
k.scene('level', Level)
k.scene('pacman', Pacman)

function start() {
  k.go('pacman')
}

start()
