import k from "../kaboom";
import beanImg from '../assets/bean.png'
import pacmanImg from '../assets/pacman.png'


export default function Pacman() {
  k.loadSprite("bean", beanImg);
  k.loadSprite("bean", beanImg);
  k.loadSpriteAtlas(pacmanImg, {
    pacman: {
      x: 0,
      y: 0,
      width: 512,
      height: 512,
      sliceX: 9,
      sliceY: 9,
      anims: {
        idle: {
          from: 2,
          to: 2,
        },
        run: {
          from: 0,
          to: 2,
          speed: 10,
          loop: true,
        },
      },
    },
    inky: {
      x: 0,
      y: 55,
      width: 512,
      height: 512,
      sliceX: 9,
      sliceY: 9,
      anims: {
        idle: {
          from: 0,
          to: 1,
          loop: true,
          speed: 5,
        },
        run: {
          from: 0,
          to: 7,
          speed: 5,
          loop: true,
        },
      },
    },
    blinky: {
      x: 0,
      y: 110,
      width: 512,
      height: 512,
      sliceX: 9,
      sliceY: 9,
      anims: {
        idle: {
          from: 0,
          to: 7,
          loop: true,
          speed: 5,
        },
        run: {
          from: 0,
          to: 7,
          speed: 5,
          loop: true,
        },
      },
    },
    pinky: {
      x: 0,
      y: 165,
      width: 512,
      height: 512,
      sliceX: 9,
      sliceY: 9,
      anims: {
        idle: {
          from: 0,
          to: 7,
          loop: true,
          speed: 5,
        },
        run: {
          from: 0,
          to: 7,
          speed: 5,
          loop: true,
        },
      },
    },
    clyde: {
      x: 0,
      y: 220,
      width: 512,
      height: 512,
      sliceX: 9,
      sliceY: 9,
      anims: {
        idle: {
          from: 0,
          to: 7,
          loop: true,
          speed: 5,
        },
        run: {
          from: 0,
          to: 7,
          speed: 5,
          loop: true,
        },
      },
    },
    cherry: {
      x: 0,
      y: 452,
      width: 512,
      height: 512,
      sliceX: 9,
      sliceY: 9,
      anims: {
        idle: {
          from: 0,
          to: 0,
        },
      },
    },
    vulnerable: {
      x: 0,
      y: 387,
      width: 512,
      height: 512,
      sliceX: 9,
      sliceY: 9,
      anims: {
        move: {
          from: 0,
          to: 7,
          loop: true,
          speed: 8
        },
      },
    },
  });

  const TILE_WIDTH = 24;
  const TILE_HEIGHT = TILE_WIDTH;

  const addGhosts = (symbols, tags) => {
    return tags.reduce(
      (prev, tag, idx) => ({
        ...prev,
        [symbols[idx]]: () => [
          k.sprite(tag, {
            anim: "idle",
            width: TILE_WIDTH,
            height: TILE_HEIGHT,
          }),
          k.anchor("center"),
          k.pos(TILE_WIDTH / 2, TILE_WIDTH / 2),
          k.area(),
          k.tile({}),
          k.z(1),
          k.agent({ speed: k.rand(50 ,100), allowDiagonals: true }),
          k.state("move", ["move", "vulnerable"]),
          tag,
          "ghost",
          { vulnerable: false }
        ],
      }),
      {}
    );
  };

  const level = k.addLevel(
    // [
    //   "---------------------",
    //   "|.........|.........|",
    //   "|.||.||||.|.||||.||.|",
    //   "|...................|",
    //   "|.||.|.|||||||.|.||.|",
    //   "|....|...|$|...|....|",
    //   "||||.||| | | |||.||||",
    //   "   |.|         |.|   ",
    //   "||||.| |||+||| |.||||",
    //   "    .  |21 34|  .    ",
    //   "||||.| ||||||| |.||||",
    //   "   |.|         |.|   ",
    //   "||||.| ||||||| |.||||",
    //   "|.........|.........|",
    //   "|.||.|||||||||||.||.|",
    //   "|..|......@......|..|",
    //   "||.|.|.|||||||.|.|.||",
    //   "|....|....|....|....|",
    //   "|.|||||||.|.|||||||.|",
    //   "|...................|",
    //   "---------------------",
    // ],
    [
      "-----------------------",
      "|@...................||",
      "|.|||.|||.|||.|./.||..|",
      "|.|$..| |.|...|./.|1|.|",
      "|.|||.|||.|||.|./.|2+.|",
      "|...|.|...|...|...|3|.|",
      "|.|||.|./.|||.|||.||..|",
      "|......./............||",
      "-----------------------",
    ],
    {
      tileWidth: TILE_WIDTH,
      tileHeight: TILE_HEIGHT,
      // pos: k.vec2(0, 0),
      tiles: {
        "@": () => [
          k.sprite("pacman", {
            anim: "idle",
            width: TILE_HEIGHT,
            height: TILE_HEIGHT,
          }),
          k.anchor("center"),
          k.pos(TILE_WIDTH / 2, TILE_WIDTH / 2),
          k.area(),
          k.body(),
          k.tile({}),
          k.z(1),
          "player",
          {
            direction: k.vec2(0, 0),
            nextDirection: null,
          },
        ],
        ...addGhosts([1, 2, 3, 4], ["inky", "blinky", "pinky", "clyde"]),
        $: () => [
          k.sprite("cherry", {
            anim: "idle",
            width: TILE_HEIGHT - 5 ,
            height: TILE_HEIGHT - 5,
          }),
          k.anchor("center"),
          k.pos(TILE_WIDTH / 2, TILE_HEIGHT / 2),
          k.area(),
          k.tile({}),
          'fruit'
        ],
        "-": () => [
          k.anchor("center"),
          k.pos(TILE_WIDTH / 2, TILE_WIDTH / 2),
          k.rect(TILE_WIDTH, TILE_HEIGHT),
          k.color(0, 0, 255),
          k.area(),
          k.body({ isStatic: true }),
          k.tile({ isObstacle: true }),
          "wall",
        ],
        "|": () => [
          k.anchor("center"),
          k.pos(TILE_WIDTH / 2, TILE_WIDTH / 2),
          k.rect(TILE_WIDTH, TILE_HEIGHT),
          k.color(0, 0, 255),
          k.area(),
          k.body({ isStatic: true }),
          k.tile({ isObstacle: true }),
          "wall",
        ],
        "/": () => [
          k.anchor("center"),
          k.pos(TILE_WIDTH / 2, TILE_WIDTH / 2),
          k.rect(TILE_WIDTH, TILE_HEIGHT),
          k.outline(1, k.BLUE),
          k.color(0, 0, 0),
          k.area(),
          k.body({ isStatic: true }),
          k.tile({ isObstacle: true }),
          "wall",
        ],
        "+": () => [
          k.rect(TILE_WIDTH, TILE_HEIGHT),
          k.color(0, 100, 255),
          k.tile({}),
        ],
        ".": () => [
          k.anchor("center"),
          k.pos(TILE_WIDTH / 2, TILE_WIDTH / 2),
          k.circle(3),
          k.pos(TILE_WIDTH / 2, TILE_HEIGHT / 2),
          k.color(155, 175, 50),
          k.area(),
          "pill",
        ],
      },
    }
  );

  // const scoreLabel = level.add([
  //   k.text(0),
  //   k.pos(530, 12),
  //   {
  //     value: 0,
  //   },
  // ]);

  const player = level.get("player")[0];
  const walls = level.get("wall");
  const ghosts = level.get("ghost");
  const fruits = level.get("fruit");
  const SPEED = 60;

  const moveGhosts = async () => {
    await k.wait(5);
    ghosts.forEach((ghost, idx) => {
      k.wait(idx * 6, () => {
        ghost.setTarget(player.pos);
      });
    });
    moveGhosts();
  };

  moveGhosts();

  // k.onUpdate('ghost', (ghost) => {
  //   if(ghost.vulnerable) {
  //     console.log('asdasd')
  //   }
  // })

  player.onPhysicsResolve(() => {
    // Set the viewport center to player.pos
    k.camPos(player.worldPos())
  })

  player.onUpdate(() => {
    k.camPos(player.worldPos())

    if (player.nextDirection) {
      const nextPos = player.pos.add(player.nextDirection.scale(2));

      if (!hasCollision(nextPos)) {
        player.play("run");

        player.direction = player.nextDirection;
        player.nextDirection = null;

        updatePacmanRotation();
      } else {
        player.play("idle");
      }
    }

    player.pos = player.pos.add(player.direction.scale(2));
  });

  player.onCollide("pill", (e) => {
    e.destroy();
    // scoreLabel.value += 1;
    // scoreLabel.text = scoreLabel.value;
  });

  player.onCollide('fruit', (e) => {
    e.destroy()
    ghosts.forEach(ghost => {
      ghost.vulnerable = true
      ghost.enterState('vulnerable')
    })
  })

  player.onCollide('ghost', (ghost) => {
    if(!ghost.vulnerable) {
      k.addKaboom(player.pos)
      player.pos = k.vec2(15,15)
      player.direction = k.vec2(0, 0)
      player.nextDirection = null
      player.play('idle')
      player.angle = 0
    } else {
      ghost.destroy()
    }
  })

  ghosts.forEach(ghost => {
    ghost.onStateEnter("vulnerable", () => {
      // console.log('asdasd')
      ghost.use(k.sprite("vulnerable", {
        anim: "move",
        width: TILE_HEIGHT,
        height: TILE_HEIGHT,
      }))
    })
  })

  k.onKeyDown("a", () => {
    player.nextDirection = k.LEFT;
  });

  k.onKeyDown("d", () => {
    player.nextDirection = k.RIGHT;
  });

  k.onKeyDown("w", () => {
    player.nextDirection = k.UP;
  });

  k.onKeyDown("s", () => {
    player.nextDirection = k.DOWN;
  });

  k.onKeyDown("left", () => {
    player.nextDirection = k.LEFT;
  });

  k.onKeyDown("right", () => {
    player.nextDirection = k.RIGHT;
  });

  k.onKeyDown("up", () => {
    player.nextDirection = k.UP;
  });

  k.onKeyDown("down", () => {
    player.nextDirection = k.DOWN;
  });

  // k.debug.inspect = true;

  function hasCollision(pos) {
    return walls.some((wall) =>
      rectRect(
        pos,
        wall.pos,
        player.width,
        player.height,
        wall.width,
        wall.height
      )
    );
  }

  function rectRect(pos1, pos2, width1, height1, width2, height2) {
    return (
      pos1.x < pos2.x + width2 &&
      pos1.x + width1 > pos2.x &&
      pos1.y < pos2.y + height2 &&
      pos1.y + height1 > pos2.y
    );
  }

  function updatePacmanRotation() {
    if (player.direction.x > 0) {
      player.angle = 0;
    } else if (player.direction.x < 0) {
      player.angle = 180;
    } else if (player.direction.y > 0) {
      player.angle = 90;
    } else if (player.direction.y < 0) {
      player.angle = -90;
    }
  }
}
