import k from "../kaboom";


export default function Level() {

  const level = k.addLevel(
    [
      "                                          ",
      "                                          ",
      "                                          ",
      "                                          ",
      "                                          ",
      "                                          ",
      "                                          ",
      "                                          ",
      "                                          ",
      "                                          ",
      "                                          ",
      "                                          ",
      "                                          ",
      "                                          ",
      "                                          ",
      "==========================================",
    ],
    {
      tileWidth: 16,
      tileHeight: 16,
      tiles: {
        "=": () => [k.rect(16, 16), k.color(0, 255, 150)],
      },
    }
  );


}
