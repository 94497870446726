import k from "../kaboom";
import throttle from 'lodash/throttle'

export default function ZoomingAndPanning() {
  let isDragging = false;
  let dragStartPos = null;

  const level = k.addLevel(
    [
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
      "--------------------||--------------------",
    ],
    {
      tileWidth: 32,
      tileHeight: 16,
      // pos: k.vec2(k.width() / 4, k.height() / 4),
      tiles: {
        "-": () => [k.rect(32, 16), k.outline(0.5), k.color(0, 255, 150)],
        "|": () => [k.rect(32, 16), k.color(192, 192, 192)],
      },
    }
  );

  function screenToWorld(screenPos) {
    const camSize = k.vec2(k.width(), k.height()).scale(1 / k.camScale().x); // Assuming uniform scale
    const topLeftWorld = k.camPos().sub(camSize.scale(0.5));
    return screenPos.scale(1 / k.camScale().x).add(topLeftWorld);
  }

  function zoomCamera(dir, screenPos) {
    console.log(dir);
    const worldPosBeforeZoom = screenToWorld(screenPos);

    const oldScale = k.camScale().x; // Assuming uniform scale
    const scaleFactor = dir.y > 0 ? 1 + 0.2 : 1 - 0.2;
    const newScale = oldScale * scaleFactor;

    k.camScale(k.vec2(newScale));

    const worldPosAfterZoom = screenToWorld(screenPos);
    const diffX = worldPosAfterZoom.x - worldPosBeforeZoom.x;
    const diffY = worldPosAfterZoom.y - worldPosBeforeZoom.y;

    k.camPos(k.camPos().x - diffX, k.camPos().y - diffY);
  }

  k.onScroll(throttle((dir) => {
    zoomCamera(dir, k.mousePos());
  }, 50));

  k.onMouseDown(() => {
    isDragging = true;
    dragStartPos = k.mousePos().clone();
  });

  k.onMouseMove(() => {
    if (isDragging) {
      const dragCurrentPos = k.mousePos();
      const diff = dragStartPos.sub(dragCurrentPos).scale(1 / k.camScale().x); // Assuming uniform scaling
      k.camPos(k.camPos().add(diff));
      dragStartPos = dragCurrentPos; // Update starting position for next frame
    }
  });

  k.onMouseRelease(() => {
    isDragging = false;
  });

  // k.onScroll((e) => {
  //   const zoomFactor = e.y > 0 ? 1 - zoomSpeed : 1 + zoomSpeed;
  //   const newScale = k.clamp(k.camScale().y * zoomFactor, minScale, maxScale);

  //   // Calculate the position adjustment to maintain the center point
  //   const oldCenter = k.camPos().scale(1 / k.camScale().y);
  //   const newCenter = k.mousePos().scale(1 / newScale);
  //   const deltaCenter = newCenter.sub(oldCenter);

  //   // console.log(newScale)
  //   // k.camScale(k.vec2(newScale));
  //   // k.camPos(k.camPos().add(deltaCenter.scale(newScale)))

  //   currentScale = newScale;
  //   currentPos = currentPos.add(deltaCenter.scale(newScale));

  //   k.camScale(k.vec2(currentScale));
  //   k.camPos(currentPos);
  // });
}
