import k from '../kaboom'

export default function Collisions() {
  k.setGravity(500)

  k.add([
    k.rect(32, 64, {radius: 5}),
    k.pos(k.width() * 0.5, 0),
    k.area(),
    k.body()
  ])

  k.add([
    k.pos(k.width() * 0.5, k.height() * 0.25),
    k.text('Hello there!'),
    k.anchor('center'),
    k.area(),
    k.body(),
  ])

  k.add([
    k.rect(k.width(), 50),
    k.color(255,255,55),
    k.pos(0, k.height()),
    k.area(),
    k.body({ isStatic: true })
  ])
}